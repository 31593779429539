const initialState = {
  isLoadingReferral: false,
  singleLoadingReferral: false,
  errorReferral: false,
  successReferral: false,
  dataReferral: false,
  rewardsReferral: false,
  profitsReferral: false,
  userReferral: false,
  codeReferral: false,
};

export const referralReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "REFERRAL_LOADING":
      return {
        ...state,
        isLoadingReferral: true,
        errorReferral: false,
        successReferral: false,
        userReferral: false,
      };
    case "REFERRAL_LOADING_SINGLE":
      return {
        ...state,
        singleLoadingReferral: true,
      };
    case "REFERRAL_DATA_SIGNUP":
      return {
        ...state,
        isLoadingReferral: false,
        errorReferral: false,
        dataReferral: true,
        successReferral: payload.message,
      };
    case "REFERRAL_SUCCESS":
      return {
        ...state,
        isLoadingReferral: false,
        successReferral: payload.message,
      };
    case "REFERRAL_CODE_SUCCESS":
      return {
        ...state,
        isLoadingReferral: false,
        codeReferral: payload.code,
        successReferral: payload.message,
      };
    case "REFERRAL_REWARDS_SUCCESS":
      return {
        ...state,
        isLoadingReferral: false,
        rewardsReferral: payload.data,
        profitsReferral: payload.profitData,
        successReferral: payload.message,
      };
    case "REFERRAL_REFERRAL_SUCCESS":
      return {
        ...state,
        isLoadingReferral: false,
        userReferral: payload.data,
        successReferral: payload.message,
        singleLoadingReferral: false,
      };
    case "REFERRAL_SUCCESS_MESSAGE":
      return {
        ...state,
        successReferral: payload.message,
      };
    case "REFERRAL_ERROR":
      return {
        ...state,
        isLoadingReferral: false,
        errorReferral: payload,
        successReferral: false,
      };
    case "REFERRAL_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
