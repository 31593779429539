import "./assets/css/reset.css";
import "./assets/css/global.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Tooltip } from "react-tooltip";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Toaster
          toastOptions={{
            duration: 5000,
            className: "toasterclass",
          }}
        />
        <Tooltip id="tooltip" className="tooltipclass" />
        <App />
      </PersistGate>
    </Provider>
  </>
);
