const initialState = {
  isLoadingPayments: false,
  singleLoadingPayments: false,
  errorPayments: false,
  successPayments: false,
  dataPayments: false,
  singlePayments: false,
};

export const paymentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PAYMENTS_LOADING":
      return {
        ...state,
        isLoadingPayments: true,
        errorPayments: false,
        successPayments: false,
        dataPayments: false,
      };
    case "PAYMENTS_LOADING_SINGLE":
      return {
        ...state,
        singlePayments: false,

        singleLoadingPayments: true,
      };
    case "PAYMENTS_SUCCESS":
      return {
        ...state,
        isLoadingPayments: false,
        errorPayments: false,
        successPayments: payload.message,
        dataPayments: payload.data,
      };
    case "PAYMENTS_SUCCESS_SINGLE":
      return {
        ...state,
        isLoadingPayments: false,
        singleLoadingPayments: false,
        errorPayments: false,
        successPayments: payload.message,
        singlePayments: payload.data,
      };
    case "PAYMENTS_SUCCESS_MESSAGE":
      return {
        ...state,
        errorPayments: false,
        successPayments: payload.message,
      };
    case "PAYMENTS_ERROR":
      return {
        ...state,
        isLoadingPayments: false,
        errorPayments: payload,
        successPayments: false,
      };
    case "PAYMENTS_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
