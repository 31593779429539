import { useEffect, useCallback } from "react";
import { codeInterceptor } from "../axios";
import { useDisconnect, useAccount } from "wagmi";
import { useDispatch } from "react-redux";
const LogoutHandler = () => {
  const dispatch = useDispatch();
  const { disconnectAsync } = useDisconnect();
  const { connector: activeConnector } = useAccount();

  const logout = useCallback(async () => {
    await disconnectAsync({
      connector: activeConnector,
    });
    dispatch({
      type: "DISCONNECT",
    });
    window.location.reload();
  }, [disconnectAsync, dispatch, activeConnector]);
  useEffect(() => {
    codeInterceptor(() => {
      logout();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LogoutHandler;
