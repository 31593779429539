const initialState = {
  isLoadingAdmin: false,
  errorAdmin: false,
  successAdmin: false,
  userListAdmin: false,
};

export const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADMIN_LOADING":
      return {
        ...state,
        isLoadingAdmin: true,
        errorAdmin: false,
        successAdmin: false,
        userListAdmin: false,
      };
    case "ADMIN_SUCCESS":
      return {
        ...state,
        isLoadingAdmin: false,
        errorAdmin: false,
        successAdmin: payload.message,
        userListAdmin: payload.data,
      };
    case "ADMIN_SUCCESS_MESSAGE":
      return {
        ...state,
        errorAdmin: false,
        successAdmin: payload.message,
      };
    case "ADMIN_ERROR":
      return {
        ...state,
        isLoadingAdmin: false,
        errorAdmin: payload,
        successAdmin: false,
      };
    case "ADMIN_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
