import { memo, useEffect, useState, useRef, useCallback } from "react";
import SlotCounter from "react-slot-counter";
import Spinner from "../Spinner";

const CustomSlot = ({ ...props }) => {
  const [isActive, setIsActive] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const containerRef = useRef(null);

  // Throttle function to avoid frequent updates
  const throttle = (fn, wait) => {
    let lastTime = 0;
    return function (...args) {
      const now = new Date().getTime();
      if (now - lastTime >= wait) {
        fn(...args);
        lastTime = now;
      }
    };
  };
  // eslint-disable-next-line
  const handleResize = useCallback(
    throttle(() => {
      setIsActive(false);
      setTimeout(() => {
        setIsActive(true);
      }, 100);
    }, 200),
    []
  );

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    const observerOptions = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin around the root
      threshold: [0, 0.1, 0.25, 0.5, 0.75, 1.0], // Trigger at various visibility levels
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line
        observer.unobserve(containerRef.current);
      }
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, [handleResize]);

  return (
    <div ref={containerRef}>
      {isVisible ? isActive ? <SlotCounter {...props} /> : <Spinner /> : null}
    </div>
  );
};

export default memo(CustomSlot);
