import { createStore } from "redux";
import { rootReducer } from "./Reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const localStorageVersion = "r2r-v3-build2.04";

const persistRootReducer = persistReducer(
  { key: localStorageVersion, storage },
  rootReducer
);

export const store = createStore(
  persistRootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);
// if()
// localStorage.setItem("r2r-version", localStorageVersion);
