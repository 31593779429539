const initialState = {
  isLoadingEstimate: false,
  errorEstimate: false,
  successEstimate: false,
  dataEstimate: false,
};

export const estimateReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ESTIMATE_LOADING":
      return {
        ...state,
        isLoadingEstimate: true,
        errorEstimate: false,
        successEstimate: false,
        dataEstimate: false,
      };
    case "ESTIMATE_SUCCESS":
      return {
        ...state,
        isLoadingEstimate: false,
        errorEstimate: false,
        successEstimate: payload.message,
        dataEstimate: payload.data,
      };
    case "ESTIMATE_FETCH":
      return {
        ...state,
        errorEstimate: false,
        isLoadingEstimate: false,
        dataEstimate: payload.data,
      };
    case "ESTIMATE_SUCCESS_MESSAGE":
      return {
        ...state,
        errorEstimate: false,
        successEstimate: payload.message,
      };
    case "ESTIMATE_ERROR":
      return {
        ...state,
        isLoadingEstimate: false,
        errorEstimate: payload,
        successEstimate: false,
        dataEstimate: false,
      };
    case "ESTIMATE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
