const initialState = {
  isLoadingClaim: false,
  errorClaim: false,
  successClaim: false,
  dataClaim: false,
};

export const claimReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "CLAIM_LOADING":
      return {
        ...state,
        isLoadingClaim: true,
        errorClaim: false,
        successClaim: false,
      };
    case "CLAIM_SUCCESS":
      return {
        ...state,
        isLoadingClaim: false,
        errorClaim: false,
        successClaim: payload.message,
        dataClaim: payload.data,
      };
    case "CLAIM_SUCCESS_MESSAGE":
      return {
        ...state,
        errorClaim: false,
        successClaim: payload.message,
      };
    case "CLAIM_ERROR":
      return {
        ...state,
        isLoadingClaim: false,
        errorClaim: payload,
        successClaim: false,
      };
    case "CLAIM_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
