import { setJWT } from "../../../utils/axios";
const initialState = {
  isLoadingSecurity: false,
  singleLoadingSecurity: false,
  errorSecurity: false,
  successSecurity: false,
  dataSecurity: false,
  isLoggedSecurity: false,
  hasnoaccountSecurity: false,
  eoaSecurity: false,
};
setJWT(localStorage.getItem("r2r_v3_jwt_token") || "");
export const securityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SECURITY_LOADING":
      return {
        ...state,
        isLoadingSecurity: true,
        errorSecurity: false,
        successSecurity: false,
      };
    case "SECURITY_LOADING_ONLY":
      return {
        ...state,
        isLoadingSecurity: true,
      };
    case "SECURITY_LOADING_STOP":
      return {
        ...state,
        isLoadingSecurity: false,
      };
    case "SECURITY_SINGUP_SUCCESS":
      return {
        ...state,
        hasnoaccountSecurity: false,
      };
    case "SECURITY_VERIFY":
      return {
        ...state,
        isLoadingSecurity: false,
        errorSecurity: false,
        hasnoaccountSecurity: true,
        successSecurity: payload.message,
      };
    case "SECURITY_USER_DATA":
      return {
        ...state,
        singleLoadingSecurity: false,
        dataSecurity: payload.data,
      };
    case "SECURITY_SINGLE_LOADING":
      return {
        ...state,
        singleLoadingSecurity: true,
      };
    case "SECURITY_LOGIN": {
      localStorage.setItem("r2r_v3_jwt_token", payload.token);
      return {
        ...state,
        isLoadingSecurity: false,
        errorSecurity: false,
        hasnoaccountSecurity: false,
        isLoggedSecurity: true,
        successSecurity: payload.message,
        eoaSecurity: payload.eoa,
      };
    }
    case "SECURITY_SUCCESS":
      return {
        ...state,
        isLoadingSecurity: false,
        singleLoadingSecurity: false,
        successSecurity: payload.message,
      };
    case "SECURITY_RESET_VALID":
      return {
        ...state,
        hasnoaccountSecurity: false,
      };
    case "SECURITY_ERROR":
      return {
        ...state,
        singleLoadingSecurity: false,
        isLoadingSecurity: false,
        errorSecurity: payload,
        successSecurity: false,
      };
    case "SECURITY_RESET":
      localStorage.removeItem("r2r_v3_jwt_token");
      return {
        ...initialState,
      };
    case "DISCONNECT":
      setJWT("");
      localStorage.removeItem("r2r_v3_jwt_token");
      localStorage.clear();
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
