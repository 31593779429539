const initialState = {
  isLoadingToken: false,
  errorToken: false,
  successToken: false,
  dataToken: false,
  isLoadingbalanceToken: false,
  balanceToken: 0.0,
};

export const tokenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "TOKEN_LOADING":
      return {
        ...state,
        isLoadingToken: true,
        errorToken: false,
        successToken: false,
      };
    case "TOKEN_SUCCESS":
      return {
        ...state,
        isLoadingToken: false,
        errorToken: false,
        successToken: payload.message,
        dataToken: payload.data,
      };
    case "TOKEN_SUCCESS_MESSAGE":
      return {
        ...state,
        errorToken: false,
        successToken: payload.message,
      };
    case "TOKEN_LOADING_BALANCE":
      return {
        ...state,
        isLoadingbalanceToken: true,
      };
    case "TOKEN_SUCCESS_BALANCE":
      return {
        ...state,
        isLoadingbalanceToken: false,
        balanceToken: payload.data,
      };
    case "TOKEN_ERROR_BALANCE":
      return {
        ...state,
        isLoadingbalanceToken: false,
        balanceToken: 0.0,
      };
    case "TOKEN_ERROR":
      return {
        ...state,
        isLoadingToken: false,
        errorToken: payload,
        successToken: false,
      };
    case "TOKEN_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
